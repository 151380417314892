/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_appsync_graphqlEndpoint": "https://6cwzfqwkxvh4rf5a7q6nndpzpe.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-43t3vk37v5asdkyygy56h4rb6m",
    "aws_cognito_identity_pool_id": "ca-central-1:ef549fe6-bca0-42bd-9229-8f6f869cf0de",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_dQuFLbJeK",
    "aws_user_pools_web_client_id": "5icv2p0o4eq1r0lrl79j4une1i",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "learnampl0d1be25835584d129268117a5e22e76e231409-dev",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
